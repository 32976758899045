import { createApp } from 'vue'
import App from './App.vue'
import './assets/base.css'
import 'amfe-flexible'
import router from './router/index'
import 'lib-flexible'

import { Notify } from 'vant'
// 引入文件
import VueClipBoard from 'vue-clipboard2'

router.beforeEach((to, from, next) => {
  const isWeixinBrowser = /micromessenger/i.test(navigator.userAgent)
  if (isWeixinBrowser) {
    console.log('当前浏览器是微信浏览器')
    if (to.path == '/fanghong') {
      next()
    } else {
      next('/fanghong')
    }
  } else {
    var token = localStorage.getItem('tokenStr')
    if (to.meta.name) {
      document.title = to.meta.name;//路由中设置页面标题
      if (token || to.meta.nacy == 'true') {//to.meta.nacy=='true'这里面的nacy就是自己在路由中设置的//不参与token验证的页面参数变量这个变量可以自己定义
        next()
      } else {
        if (to.path == '/login') {
          next()
        } else {
          Notify({
            type: 'danger',
            message: "您还没有登录！",
            duration: 2000,
          });
          setTimeout(() => {
            next('/login')
          }, 2000);

        }
      }
    }
  }
});

const app = createApp(App)
app.use(router).use(Notify).use(VueClipBoard)
app.mount('#app')
