import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            meta: {
                name: "首页",
                nacy: "false",//是否不做token验证  false:做  true:不做
            },
            component: () => import('../views/home')
        },
        {
            meta: {
                name: "首页",
                nacy: "false",//是否不做token验证
            },
            path: '/home',
            component: () => import('../views/home')
        },
        {
            meta: {
                name: "登录",
                nacy: "true",//是否不做token验证
            },
            path: '/login',
            component: () => import('../views/login')
        },
        {
            meta: {
                name: "个人中心",
                nacy: "false",//是否不做token验证
            },
            path: '/mine',
            component: () => import('../views/mine')
        },
        {
            meta: {
                name: "获取积分",
                nacy: "false",//是否不做token验证
            },
            path: '/point',
            component: () => import('../views/point')
        },
        {
            meta: {
                name: "兑换积分",
                nacy: "false",//是否不做token验证
            },
            path: '/exchange',
            component: () => import('../views/point/exchange')
        },
        {
            meta: {
                name: "兑换记录",
                nacy: "false",//是否不做token验证
            },
            path: '/history',
            component: () => import('../views/history')
        },
        {
            meta: {
                name: "搜索",
                nacy: "false",//是否不做token验证
            },
            path: '/search',
            component: () => import('../views/search')
        },
        {
            meta: {
                name: "注册",
                nacy: "true",//是否不做token验证
            },
            path: '/register',
            component: () => import('../views/register')
        },
        {
            meta: {
                name: "找回密码",
                nacy: "true",//是否不做token验证
            },
            path: '/findpwd',
            component: () => import('../views/findpwd')
        },
        {
            meta: {
                name: "详情",
                nacy: "false",//是否不做token验证
            },
            path: '/detail',
            component: () => import('../views/detail')
        },
        {
            meta: {
                name: "购买积分",
                nacy: "false",//是否不做token验证
            },
            path: '/buy',
            component: () => import('../views/buy')
        },
        {
            meta: {
                name: "积分记录",
                nacy: "false",//是否不做token验证
            },
            path: '/trades',
            component: () => import('../views/trades')
        },
        {
            meta: {
                name: "使用教程",
                nacy: "true",//是否不做token验证
            },
            path: '/tutorial',
            component: () => import('../views/tutorial')
        },
        {
            meta: {
                name: "账号设置",
                nacy: "false",//是否不做token验证
            },
            path: '/setting',
            component: () => import('../views/mine/setting')
        },
        {
            meta: {
                name: "修改昵称",
                nacy: "false",//是否不做token验证
            },
            path: '/resetname',
            component: () => import('../views/mine/reset/resetname')
        },
        {
            meta: {
                name: "修改密码",
                nacy: "false",//是否不做token验证
            },
            path: '/resetpwd',
            component: () => import('../views/mine/reset/resetpwd')
        },
        {
            meta: {
                name: "防红",
                nacy: "true",//是否不做token验证
            },
            path: '/fanghong',
            component: () => import('../views/fanghong')
        },
        {
            meta: {
                name: "分享",
                nacy: "true",//是否不做token验证
            },
            path: '/shareid',
            component: () => import('../views/share')
        },
        // ,
        // {
        //     meta: {
        //         name: "管理员登录",
        //         nacy: "true",//是否不做token验证
        //     },
        //     path: '/adminlogin',
        //     component: () => import('../views/manager/login')
        // }
    ]
})

export default router